export default class ScheduleItemModel {
  constructor(){
    this.id = null;
    this.date = '';
    this.type = 'GENERAL';
    this.text = '';
    this.scheduleValid = {
      date:{
        value: 'date',
        isValid:false,
      },
      type:{
        value: 'type',
        isValid:false,
      },
      text:{
        value: 'text',
        isValid:false,
      }
    }
  }
  setAddScheduleData(date){
    this.date = date;
  }
  setModifyScheduleData(data){
    const {id, date, type, title, writer} = data;
    this.id = id;
    this.date = date;
    this.type = type;
    this.text = title;
    this.writerId = writer.id;
  }
  getData(){
    let obj = {
      date : this.date,
      type : this.type,
      text : this.text,
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      id : this.id,
      date : this.date,
      type : this.type,
      text : this.text,
    }
    return obj;
  }
}