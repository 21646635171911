<template>
  <PageWithLayout :isBodyBg="false">
    <GroupBoxWidget>
      <!-- default -->
      <BoxWidget
        iconName="logo"
        iconBgColor="#6CDFC3"
        boxBgColor="#6CDFC3"
        title="활성화된 서비스 회원"
        :isProgress="true"
        :grid="33.33"
        :value="258321"
        :totalValue="258362"
        :isShowPercent="true"
        :isShowTotal="true"/>
      <BoxWidget
        iconName="logo"
        iconBgColor="#86BEFF"
        boxBgColor="#86BEFF"
        title="활성화된 카드"
        :isProgress="true"
        :grid="33.33"
        :value="251260"
        :totalValue="258362"
        :isShowPercent="true"
        :isShowTotal="true"/>
      <BoxWidget
        iconName="logo"
        iconBgColor="#FFD82A"
        boxBgColor="#FFD82A"
        title="레몬지갑 잔액"
        :grid="33.33"
        :value="598120500"
        unit="원"/>
    </GroupBoxWidget>
    <GroupBoxWidget>
      <BoxWidget
        title="가맹점"
        :grid="33.33"
        :value="10"/>
      <BoxWidget
        title="정산요청건"
        :grid="33.33"
        :value="2"/>
      <BoxWidget
        title="정산대기건"
        :grid="33.33"
        :value="12"/>
    </GroupBoxWidget>
    <Widget title="schedule" :grid="33.33" :isWide.sync="viewModel.isWideSchedule">
      <Calendar
        :calendarData="viewModel.calendarScheduleData"
        :scheduleList="viewModel.scheduleModel.scheduleDataList"
        @getData="viewModel.getScheduleData()"
        @onAddSchedule="date => viewModel.onAddSchedule(date)"
        @onClickDeleteSchedule="scheduleData => viewModel.onClickDeleteSchedule(scheduleData)"
        @onClickModifySchedule="scheduleData => viewModel.onClickModifySchedule(scheduleData)">
        <template v-slot:infoNotes>
          <span
            v-for="note in viewModel.infoNotes"
            :key="`note${note.id}`"
            class="item_notes">
            <span
              class="color_notes"
              :style="`background-color:${ $options.filters.convertIdToColor(note.id, 'schedule_type') }`"></span>{{note.text}}
          </span>
        </template>
      </Calendar>
    </Widget>
    <template v-slot:popup>
      <SchedulePopup
        v-if="viewModel.isSchedulePopup"
        :isSchedulePopupModify="viewModel.isSchedulePopupModify"
        :scheduleData="viewModel.scheduleItemModel"
        @onClickClose="viewModel.onClickCloseSchedulePopup()"
        @onClickComplete="viewModel.onClickCompleteSchedulePopup()"
        @onClickDelete="scheduleData => viewModel.onClickDeleteSchedule(scheduleData)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import GroupBoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/GroupBoxWidget';
import BoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/BoxWidget';
import Widget from '@lemontree-ai/lemontree-admin-common-front/components/common/widget/Widget';
import Calendar from '@lemontree-ai/lemontree-admin-common-front/components/common/calendar/Calendar';
import SchedulePopup from '@lemontree-ai/lemontree-admin-common-front/components/common/calendar/popup/SchedulePopup';
// viewModel
import DashboardViewModel from '@/views/dashboard/dashboard/viewModel/DashboardViewModel'

export default {
  name:'Dashboard',
  components:{
    PageWithLayout,
    GroupBoxWidget,
    BoxWidget,
    Widget,
    Calendar,
    SchedulePopup
  },
  beforeMount(){
    this.viewModel.init();
  },
  mounted(){
    this.viewModel.calendarElement = this.$refs.calendar;
  },
  watch:{
    'viewModel.isWideSchedule':{
      deep:true,
      handler(newVal, oldVal) {
        this.viewModel.calendarScheduleData.isMiniType = !this.viewModel.isWideSchedule
      },
    }
  },
  data(){
    return{
      viewModel: new DashboardViewModel(),
    }
  },
}
</script>