import { convertDateFormat, dateDiff, calcDate, getweekDay, getDayToWeek, getWeeklyDateList, getMonthlyDateList, getMonthLastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

export default class ScheduleModel {
  constructor(){
    this.scheduleDataList = [];
  }
  setData(array){
    let scheduleDataList = [];
    array.forEach(data => {
      let result = data;
      result.date = convertDateFormat(result.date,'YYYY-MM-DD');
      const { id, text, type, writer, createdAt, updatedAt } = result
      let scheduleData = {};
      scheduleData.date = result.date;
      scheduleData.id = id;
      scheduleData.title = text;
      scheduleData.type = type;
      scheduleData.writer = writer;
      scheduleData.createdAt = createdAt;
      scheduleData.updatedAt = updatedAt;
      if(scheduleDataList.length > 0 && scheduleDataList.find(item => item.date === result.date)){
        const duplicateDataIndex = scheduleDataList.findIndex(item => item.date === result.date);
        scheduleDataList[duplicateDataIndex].scheduleDataList.push(scheduleData)
      }else{
        let newData = {};
        newData.date = result.date;
        newData.scheduleDataList = [scheduleData];
        scheduleDataList.push(newData);
      }
      this.scheduleDataList = scheduleDataList;
    })
  }
}