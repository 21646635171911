import ScheduleModel from '@/views/dashboard/dashboard/model/ScheduleModel'
import ScheduleItemModel from '@/views/dashboard/dashboard/model/ScheduleItemModel'
//ScheduleItemModel
import { getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils';
import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class DashboardViewModel {
  constructor() {
    this.infoNotes = [];
    this.calendarElement = undefined;
    this.calendarScheduleData = {
      targetMonth: '', // 월간 - 기준월 YYYY-MM
      searchDataRangeMonthly: { start_date:'', end_date:'' }, // 월간 조회 일
      limit: { minMonth:'', maxMonth:'', isMaxMonthToday:false }, // 월간 - 기준 최소시작일
      isSchedule: true,
      isMiniType: true,
    };
    this.scheduleModel = {};
    this.scheduleItemModel = {};
    this.isWideSchedule = false;
    this.isSchedulePopup = false;
    this.isSchedulePopupModify = false;
  }
  init(){
    this.scheduleModel = new ScheduleModel();
    this.scheduleItemModel = new ScheduleItemModel();
    this.infoNotes = getSelectDataList('schedule_type');
    this.setMonthDate();
  }
  setMonthDate(){
    this.calendarScheduleData.targetMonth = convertDateFormat(new Date(), 'YYYY-MM');
    if(this.calendarElement){
      this.calendarElement.setMonthSearchDate();
    }
    if(this.calendarScheduleData.searchDataRangeMonthly.start_date && this.calendarScheduleData.searchDataRangeMonthly.end_date){
      this.getScheduleData();
    }
    if(this.calendarElement){
      this.calendarElement.setMonthData();
    }
  }
  onAddSchedule(date){
    this.scheduleItemModel.setAddScheduleData(date);
    this.isSchedulePopupModify = false;
    this.isSchedulePopup = true;
  }
  onClickCloseSchedulePopup(){
    this.onCloseSchedulePopup();
  }
  onClickCompleteSchedulePopup(){
    if(this.isSchedulePopupModify){
      this.putScheduleData()
    }else{
      this.postScheduleData()
    }
  }
  onCloseSchedulePopup(){
    this.isSchedulePopup = false;
    this.scheduleItemModel = new ScheduleItemModel();
  }
  onClickDeleteSchedule(scheduleData){
    const requestDelete = () => {
      this.deleteScheduleData(scheduleData.id);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `스케줄을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  onClickModifySchedule(scheduleData){
    this.scheduleItemModel.setModifyScheduleData(scheduleData);
    this.isSchedulePopupModify = true;
    this.isSchedulePopup = true;
  }

  // 상세
  getScheduleData(){
    const { start_date, end_date } = this.calendarScheduleData.searchDataRangeMonthly;
    const searchDate = {
      startDate:start_date,
      endDate:end_date,
    }
    const query = makeQueryStringByObject(searchDate);
    const path = `${apiPath.SCHEDULE_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      let scheduleDataList = resultData.data;
      this.scheduleModel.setData(scheduleDataList);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 등록
  postScheduleData(){
    const path = `${apiPath.SCHEDULE_LIST}`;
    const data = this.scheduleItemModel.getData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.getScheduleData();
      this.onCloseSchedulePopup();
      store.dispatch('commonToast/fetchToastStart', '스케줄이 등록되었습니다');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 수정
  putScheduleData(){
    const path = `${apiPath.SCHEDULE_LIST}/${this.scheduleItemModel.id}`;
    const data = this.scheduleItemModel.getData();
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.getScheduleData();
      this.onCloseSchedulePopup();
      store.dispatch('commonToast/fetchToastStart', '스케줄이 수정되었습니다');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 삭제
  deleteScheduleData(id){
    const path = `${apiPath.SCHEDULE_LIST}/${id}`;
    DELETE_REQUEST(path).then(
    (success) => {
      this.getScheduleData();
      this.onCloseSchedulePopup();
      store.dispatch('commonToast/fetchToastStart', '스케줄이 삭제되었습니다');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}